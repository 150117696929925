<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New Tag
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined type="info" dismissible prominent icon="mdi-tag">
         <h3>
          Tags play a crucial role in categorizing incidents within UnStruct, providing a flexible and powerful way to organize and manage incident data. With tags, UnStruct enables:
         </h3>
          <ul>
            <li>Customizable tag configurations for precise incident categorization.</li>
            <li>Automatic application of tags to incidents using UnStruct's NLP/AI engine, streamlining the categorization process.</li>
            <li>Enhanced incident filtering and search capabilities, ensuring that the right incidents are identified and addressed.</li>
            <li>Entity extraction and categorization, enabling the identification of key entities within incidents.</li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
              <v-card outlined class="mb-3">
                <!-- Title Section -->
                <v-card-title>
                  {{ item.name }}
                  <v-spacer></v-spacer>
                  <v-chip color="primary" text-color="white" class="ml-auto">
                    <v-icon left>mdi-domain</v-icon>
                    {{ item.count }}
                  </v-chip>
                </v-card-title>

                <!-- Subtitle Section -->
                <v-card-subtitle>
                  <span>Source: {{ item.source }}</span>
                  <v-chip small class="ml-2">{{ item.tag_type.name }}</v-chip>
                </v-card-subtitle>

                <!-- Text Section -->
                <v-card-text>
                  <div>Description: {{ item.description }}</div>
                  <div>URI: {{ item.uri }}</div>
                  <div>External ID: {{ item.external_id }}</div>
                  <v-simple-checkbox v-model="item.discoverable" disabled label="Discoverable"></v-simple-checkbox>
                </v-card-text>

                <!-- Actions Section -->
                <v-card-actions>
                  <v-menu bottom left>
                    <template #activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="createEditShow(item)">
                        <v-list-item-title>View / Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="removeShow(item)">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.discoverable="{ item }">
              <v-simple-checkbox v-model="item.discoverable" disabled />
            </template>
            <template v-slot:item.tag_type.name="{ item }">
              <span v-if="item.tag_type">
                {{ item.tag_type.name }}
              </span>
            </template>
            <template v-slot:item.data-table-actions="{ item }">
              <v-menu bottom left>
                <template #activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="createEditShow(item)">
                    <v-list-item-title>View / Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="removeShow(item)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import DeleteDialog from "@/tag/DeleteDialog.vue"
import NewEditSheet from "@/tag/NewEditSheet.vue"
import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"

export default {
  name: "TagTable",

  data() {
    return {
      headers: [],
    }
  },

  components: {
    DeleteDialog,
    NewEditSheet,
    SettingsBreadcrumbs,
  },

  computed: {
    ...mapFields("tag", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("tag", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>
